import React, {useState} from 'react';
import {auth, generateUserDocument} from '../providers/FirebaseAuthProvider';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const createUserWithEmailAndPasswordHandler = async (
      event, email, password) => {
    event.preventDefault();
    setError(null); // Reset errors
    try {
      const {user} = await auth.createUserWithEmailAndPassword(email, password);
      generateUserDocument(user);
    }
    catch (error) {
      console.log(error);
      setError(error.message);
    }

    setEmail('');
    setPassword('');
  };

  const onChangeHandler = event => {
    const {name, value} = event.currentTarget;
    switch (name) {
      case 'userEmail':
        setEmail(value);
        break;
      case 'userPassword':
        setPassword(value);
        break;
      default:
        return;
    }
  };

  return (
      <div className="container">
        <div className="row">
          <div className="col-6 offset-3">
            <h1>Sign Up</h1>
            <div>
              {error && <Alert variant="danger">{error}</Alert>}
              <Form>
                <Form.Group controlId="userEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                      type="email"
                      placeholder="Enter email"
                      name="userEmail"
                      value={email}
                      onChange={event => onChangeHandler(event)}
                  />
                </Form.Group>

                <Form.Group controlId="userPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                      type="password"
                      placeholder="Password"
                      name="userPassword"
                      value={password}
                      onChange={event => onChangeHandler(event)}
                  />
                </Form.Group>
                <Button
                    variant="primary"
                    type="submit"
                    onClick={event => {
                      createUserWithEmailAndPasswordHandler(event, email,
                          password);
                    }}
                >
                  Submit
                </Button>
              </Form>

              {/*<button*/}
              {/*    className="bg-red-500 hover:bg-red-600 w-full py-2 text-white"*/}
              {/*>*/}
              {/*  Sign In with Google*/}
              {/*</button>*/}
              {/*<p className="text-center my-3">*/}
              {/*  Already have an account?{' '}*/}
              {/*  <Link to="/" className="text-blue-500 hover:text-blue-600">*/}
              {/*    Sign in here*/}
              {/*  </Link>*/}
              {/*</p>*/}
            </div>
          </div>
        </div>
      </div>
  );
};

export default Register;