import React, {useState} from 'react';
import {
  Link,
} from 'react-router-dom';
import {auth} from '../providers/FirebaseAuthProvider';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const LogIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const signInWithEmailAndPasswordHandler = (event, email, password) => {
    event.preventDefault();
    auth.signInWithEmailAndPassword(email, password).catch(error => {
      setError('Error signing in with password and email!');
      console.error('Error signing in with password and email', error);
    });
  };

  const onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;

    if (name === 'userEmail') {
      setEmail(value);
    }
    else if (name === 'userPassword') {
      setPassword(value);
    }
  };

  return (
      <div className="container">
        <div className="row">
          <div className="col-6 offset-3">
            <h1>Log In</h1>
            <div>
              {error && <Alert variant="danger">{error}</Alert>}

              <Form>
                <Form.Group controlId="userEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                      type="email"
                      placeholder="Enter email"
                      name="userEmail"
                      value={email}
                      onChange={event => onChangeHandler(event)}
                  />
                </Form.Group>

                <Form.Group controlId="userPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                      type="password"
                      placeholder="Password"
                      name="userPassword"
                      value={password}
                      onChange={event => onChangeHandler(event)}
                  />
                </Form.Group>

                <Button
                    variant="primary"
                    type="submit"
                    onClick={event => {
                      signInWithEmailAndPasswordHandler(event, email, password);
                    }}
                >
                  Submit
                </Button>

              </Form>

              {/*<p className="text-center my-3">or</p>*/}
              {/*<button*/}
              {/*    className="bg-red-500 hover:bg-red-600 w-full py-2 text-white">*/}
              {/*  Sign in with Google*/}
              {/*</button>*/}
              {/*<p className="text-center my-3">*/}
              {/*  Don't have an account?{' '}*/}
              {/*  <Link to="signUp" className="text-blue-500 hover:text-blue-600">*/}
              {/*    Sign up here*/}
              {/*  </Link>{' '}*/}
              {/*  <br/>{' '}*/}
              {/*  <Link to="passwordReset"*/}
              {/*        className="text-blue-500 hover:text-blue-600">*/}
              {/*    Forgot Password?*/}
              {/*  </Link>*/}
              {/*</p>*/}
            </div>
          </div>
        </div>
      </div>
  );
};
export default LogIn;