import React, {useState} from 'react';
import {
  Link,
} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {auth} from '../providers/FirebaseAuthProvider';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [error, setError] = useState(null);

  const onChangeHandler = event => {
    const {name, value} = event.currentTarget;
    if (name === 'userEmail') {
      setEmail(value);
    }
  };
  const sendResetEmail = event => {
    event.preventDefault();
    auth
        .sendPasswordResetEmail(email)
        .then(() => {
          setEmailHasBeenSent(true);
          setTimeout(() => {
            setEmailHasBeenSent(false);
          }, 3000);
        })
        .catch((error) => {
          setError(error.message);
        });
  };
  return (
      <div className="container">
        <div className="row">
          <div className="col-6 offset-3">
            <h1>Reset your Password</h1>
            <div>

              {
                emailHasBeenSent &&
                <Alert variant="success">An email has been sent to you!</Alert>
              }
              {error && <Alert variant="danger">{error}</Alert>}

              <Form>
                <Form.Group controlId="userEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                      type="email"
                      placeholder="Enter email"
                      name="userEmail"
                      value={email}
                      onChange={event => onChangeHandler(event)}
                  />
                </Form.Group>

                <Button
                    variant="primary"
                    type="submit"
                    onClick={event => {
                      sendResetEmail(event);
                    }}
                >
                  Submit
                </Button>

              </Form>
              <Button
                  variant="link"
                  href="/user/login"
              >
                &larr; back to sign in page
              </Button>


            </div>
          </div>
        </div>
      </div>
  );
};
export default PasswordReset;