import React, {useContext} from 'react';
import {UserContext} from '../providers/UserProvider';
import {auth} from '../providers/FirebaseAuthProvider';

const ProfilePage = () => {
  const user = useContext(UserContext);
  const {email} = user;
  console.log(user);
  return (
      <div>
        <h1>{email}</h1>
        <button
            onClick={() => {
              auth.signOut();
            }}
        >
          Sign out
        </button>
      </div>
  );
};
export default ProfilePage;