import React, {Component} from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

export default class NavBarComponent extends Component {

  componentDidMount() {
    console.log('[NavBarComponent.jsx] componentDidMount', this.props);
  }

  componentDidUpdate() {
    console.log('[NavBarComponent.jsx] componentDidUpdate', this.props);
  }

  render() {

    return (
        <>
          <header className="App-header">
            <Navbar bg="light" expand="lg">
              <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav"/>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <Nav.Link href="/">Home</Nav.Link>
                  <Nav.Link href="/user">User</Nav.Link>
                  <Nav.Link href="/user/profile">Profile</Nav.Link>
                  <Nav.Link href="/user/login">Log In</Nav.Link>
                  <Nav.Link href="/user/register">Register</Nav.Link>
                  <Nav.Link href="/user/reset-password">Reset Password</Nav.Link>
                </Nav>
                <div>

                  {
                    this.props &&
                    this.props.user
                    ? 'Signed in as ' + this.props.user.email
                    : 'Logged Out'
                  }
                </div>
              </Navbar.Collapse>
            </Navbar>
          </header>
        </>
    );
  }
}