import React from 'react';
import './App.scss';
import NavBarComponent from './layout/NavBarComponent';
import * as firebase from 'firebase/app';
import {config} from './providers/FirebaseAuthProvider';
import {
  FirebaseAuthConsumer,
  FirebaseAuthProvider,
  IfFirebaseAuthed,
  IfFirebaseUnAuthed,
} from '@react-firebase/auth';
import UserPages from './auth/UserPages';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Properties from './properties/Properties';

function App() {
  return (
      <FirebaseAuthProvider {...config} firebase={firebase}>
        <FirebaseAuthConsumer>
          <NavBarComponent/>
        </FirebaseAuthConsumer>

        <IfFirebaseUnAuthed>
          <Router>
            <Route path="/user" component={UserPages}/>
          </Router>
        </IfFirebaseUnAuthed>

        <IfFirebaseAuthed>
          <Router>
            <Switch>
              <Route path="/user" component={UserPages}/>
              <Route path="/properties" component={Properties}/>
              <Route path="/" component={Properties}/>
            </Switch>
          </Router>
        </IfFirebaseAuthed>

      </FirebaseAuthProvider>
  );
}

export default App;
