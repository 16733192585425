import React, {Component, createContext} from 'react';
import {auth, generateUserDocument} from './FirebaseAuthProvider';

export const UserContext = createContext({user: null});

class UserProvider extends Component {
  state = {
    user: null,
  };

  componentDidMount = async () => {

    // Go get User from Firestore
    auth.onAuthStateChanged(async userAuth => {
      console.log('[UserProvider.jsx] componentDidMount', userAuth);
      const user = await generateUserDocument(userAuth);
      this.setState({user});
      console.log('[UserProvider.jsx] componentDidMount', this.state);
    });
  };

  render() {
    const {user} = this.state;

    return (
        <UserContext.Provider value={user}>
          {this.props.children}
        </UserContext.Provider>
    );
  }
}

export default UserProvider;