import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCog, faCheck} from '@fortawesome/pro-solid-svg-icons';

export default class Properties extends Component {

  componentDidMount() {
    console.log('[Properties.jsx] componentDidMount', this.props);
  }

  componentDidUpdate() {
    console.log('[Properties.jsx] componentDidUpdate', this.props);
  }

  render() {

    return (
        <>
          <h2>Propertiiiies</h2>
        </>
    );
  }
}