import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

export const config = {
  apiKey: "AIzaSyDdVY-eN_UwW_vlENczTDSO61x9ChjUI5k",
  authDomain: "summit-react-project.firebaseapp.com",
  databaseURL: "https://summit-react-project.firebaseio.com",
  projectId: "summit-react-project",
  storageBucket: "summit-react-project.appspot.com",
  messagingSenderId: "293441682947",
  appId: "1:293441682947:web:1c9997d3d6b246c9d35836",
  measurementId: "G-74R0NLJQVQ"
};

firebase.initializeApp(config);
export const auth = firebase.auth();
export const firestore = firebase.firestore();

// TODO
// const provider = new firebase.auth.GoogleAuthProvider();

export const generateUserDocument = async (user, additionalData) => {
  if (!user) return;
  const userRef = firestore.doc(`users/${user.uid}`);
  const snapshot = await userRef.get();
  if (!snapshot.exists) {
    const { email } = user;
    try {
      await userRef.set({
        email,
        ...additionalData
      });
    } catch (error) {
      console.error("Error creating user document", error);
    }
  }
  return getUserDocument(user.uid);
};

const getUserDocument = async uid => {
  if (!uid) return null;
  try {
    const userDocument = await firestore.doc(`users/${uid}`).get();
    return {
      uid,
      ...userDocument.data()
    };
  } catch (error) {
    console.error("Error fetching user", error);
  }
};