import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import LogIn from './LogIn';
import Register from './Register';
import PasswordReset from './PasswordReset';
import {IfFirebaseAuthed, IfFirebaseUnAuthed} from '@react-firebase/auth';
import ProfilePage from './ProfilePage';

function UserPages() {
  return (
      <>
        <IfFirebaseUnAuthed>
          <Router>
            <Switch>
              <Route path="/user" exact>
                <Redirect to='/user/login'/>
              </Route>
              <Route path="/user/profile" exact>
                <Redirect to='/user/login'/>
              </Route>
              <Route path="/user/login" component={LogIn}/>
              <Route path="/user/register" component={Register}/>
              <Route path="/user/reset-password" component={PasswordReset}/>
            </Switch>
          </Router>
        </IfFirebaseUnAuthed>
        <IfFirebaseAuthed>
          <ProfilePage/>
        </IfFirebaseAuthed>
      </>
  );
}

export default UserPages;